export const HOVER_ITEM_COLOR = '#625A41';
export const HEADER_BG_COLOR = 'transparent';
export const BLACK = '#2e332f';
export const WHITE = '#fff';
export const MAIN_COLOR = '#817655';
export const COMPLEMENTARY_MAIN_COLOR = '#556081';
export const DARK_GREEN = '#768155';
export const MAROON = '#816055'
export const NAPOLEON_BG = '#3C3C3C';
export const NAPOLEON_TEXT_COLOR = '#999999';
export const NAPOLEON_BROWN_COLOR = '#C2B280';
export const NAPOLEON_COMPLEMENT_COLOR = '#8090C2'
export const NAPOLEON_COMPLEMENT_HOVER_COLOR = '#43558c'
export const NAPOELON_CAPTION_BROWN_COLOR = '#f8f7f1';