export const menuItems = ['Home', 'About Us', 'Products & Services', 'Social Awareness', 'Contact Us', 'Recruitment', 'Booking', 'Login']
export const MAPS_LINK = 'https://maps.app.goo.gl/Z5fFNVq9iFpBcWiN8?g_st=ic';
export const WHATSAPP_LINK = 'https://wa.me/6288223791220';
export const RECRUITMENT_LINK = 'https://forms.gle/4YxEP9H1LdzWszLY6';
export const BARBERSHOP_MOBILE_NO = '+6288223791220';
export const BARBERSHOP_EMAIL = 'support@napoleonbarbershop.com';
export const BARBERSHOP_INSTAGRAM = 'napoleonsbarbershopid';
export const BARBERSHOP_TIKTOK = '@napoleonsbarbershopid';
export const BARBERSHOP_TWITTER = 'napoleonsbarber';
export const CAROUSEL_RESPONSIVE_SIZES_LOGO = {
  desktop: {
    breakpoint: { max: 3000, min: 1000 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1000, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};
export const CAROUSEL_RESPONSIVE_SIZES_CHARITY = {
  desktop: {
    breakpoint: { max: 3000, min: 1000 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1000, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};

export const ADMIN_EMAIL = 'napoleonsbarbershop@gmail.com';
export const ADMIN_EMAIL_2 = 'naposbarbershop@gmail.com';

export const MEMBERSHIP_PLANS = [
  {
    label: '3 Month Membership',
    eventKey: 3
  },
  {
    label: '6 Month Membership',
    eventKey: 6
  }
]

// export const SIX_MONTHS_IN_MILLISECONDS = 15778800000;
// export const THREE_MONTHS_IN_MILLISECONDS = 7889231500;